var $openBtnOfMb;
$(document).ready(function () {
    //global
    common();
    jumpMenu();
    appWidgetFeed($('footer'));
    //index
    lazyYoutube();
    bannerSlider();
    //course
    courseMobileMenu();
    //product
    productDetailSlider();
    productSharing();
    //blog
    setTopMenuTitleFromBlogView();
    blogSharing();
   
});

function lazyYoutube() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeYt'));
    });
}

function initViewLazyOfIframe($targetElem) {
    
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if ($targetElem.data('isEnable') != true) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    $targetElem.data('isEnable',true);
                }
            }
        });
    }
}

function jumpMenu() {

    var jumpOffset = -$('.header').height();
    console.log('jumpMenu => offset:' + jumpOffset);

    //選單跳段落
    $('.header a').click(function () {

        console.log('click menu link:' + this.hash);

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash, jumpOffset, 500);
        }
        // return false;
    });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:');
        Pace.on('done', function () {
            jumpSection(newjumpHash, jumpOffset, 500);
            // return false;
        });
    }

}

//課程手機滑動選單
function courseMobileMenu() {

    var jumpOffset = -$('.header').height();
    console.log('jumpMenu => offset:' + jumpOffset);

    $('.course-mobile-menu a').click(function () {

        console.log('click menu link:' + this.hash);

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash, jumpOffset, 500);
        }

        return false;
    });
}

function appWidgetFeed($initLoadElemWrapper) {
    if ($initLoadElemWrapper.length > 0) {
        var isEnable = false;
        $initLoadElemWrapper.on('inview', function (event, isInView) {
            if (isInView && isEnable == false) {
                isEnable = true;
                var appWidgets = document.createElement("script");
                appWidgets.async = true;
                if (window.MYAPP.hasOwnProperty('apps_widget_endpoint')) {
                    appWidgets.src = window.MYAPP.apps_widget_endpoint;
                    $("head").append(appWidgets);
                }
            }
        });
    }
}

function bannerSlider() {

    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {
        console.log('init banner');

        if ($bannerSlider.find('.ms-view').length == 0) {
            try {
                var slider = new MasterSlider();

                slider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                slider.control('bullets');

                slider.setup('bannerSlider', {
                    width: 1280,
                    height: 640,
                    minHeight: 400,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true
                });
                $('.master-skeleton-loader').remove();
            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }


    }
}

function productDetailSlider() {
    var $productSlider = $('#productSlider');
    if ($productSlider.length > 0) {


        if ($productSlider.find('.ms-view').length == 0) {
            try {
                var slider = new MasterSlider();

                slider.control(
                    'thumblist', {
                        autohide: false,
                        overVideo: true,
                        dir: 'h',
                        speed: 17,
                        inset: false,
                        arrows: false,
                        hover: false,
                        customClass: '',
                        align: 'bottom',
                        type: 'thumbs',
                        margin: 5,
                        width: 100,
                        height: 100,
                        space: 5
                    });
                slider.setup('productSlider', {
                    width: 500,
                    height: 500,
                    space: 5
                });
                $('.master-skeleton-loader').remove();
            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($productSlider);
            }
        }



    }
}

function setTopMenuTitleFromBlogView() {

    var $logolink = $('.header_top').find('.logolink-scroll');
    if ($('.post-detail-row').length > 0) {

        $logolink.hide();
        $('#page-title').show().html($('.post-body h1').html());

    } else {
        $logolink.show();
    }
}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

function initJsSocial($targetElem) {
    Pace.on('done', function () {
        if ($targetElem.length > 0) {
            $targetElem.jsSocials({
                shares: ["twitter", "facebook"]
            });
        }
    })

}
